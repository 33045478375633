import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import HeroImage from '../../../static/images/press-room/press-room-hero-image-alt.jpg';

const StyledHeroSectionComponent = styled.div`
  margin-bottom: 36px;
  position: relative;

  .styled-row {
    background-image: url(${HeroImage});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 84vh;

    .statement,
    .designation {
      font-size: 22px;
      line-height: calc(22px + 6px);
    }

    .styled-col {
      display: flex;
      align-items: flex-end;
      color: ${props => props.theme.whitePalette.original};
      z-index: 1;
      margin-top: 89px;
      .statement {
        ::after {
          content: '“';
          font-size: 22em;
          position: absolute;
          top: 50%;
          left: -10%;
          z-index: 0;
          opacity: 0.2;
          color: ${props => props.theme.primeYellowPalette.original};
        }
        ::before {
          content: '”';
          font-size: 22em;
          position: absolute;
          top: 100%;
          right: 0%;
          z-index: 0;
          opacity: 0.2;
          color: ${props => props.theme.primeYellowPalette.original};
        }
      }

      .name {
        margin-top: 8px;
        font-size: 2.2em;
        line-height: 1;
        font-weight: bolder;
        color: ${props => props.theme.primeYellowPalette.original};
      }

      .label-jpy {
        margin-top: 26px;
        text-align: right;
      }
    }
  }

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    background: #003366d4;
  }
`;

function HeroSectionComponent() {
  return (
    <StyledHeroSectionComponent>
      <Row type="flex" justify="center" align="middle" className="styled-row">
        <Col xxl={16} lg={17} md={20} sm={20} xs={20} className="styled-col">
          <div>
            <p className="statement">
              Real estate business has evolved rapidly in the past ten years
              driven by the millennial market and technological advancements.
              While most developers and investors still rely on conventional
              strategies and practices, a few have already taken advantage of
              newer perspectives and better insights that have proven to be the
              most effective in this new generation.
            </p>
            <div className="label-jpy">
              <div className="name">Jet Yu</div>
              <div className="designation">
                Founder & CEO of PRIME Philippines
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </StyledHeroSectionComponent>
  );
}

export default HeroSectionComponent;
