import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

const mediaLogos = [
  {
    channelName: 'bloomberg-tv-ph',
    logoSrc: '../../images/news-channels/bloomberg-tv.png',
    grid: {
      lg: 6,
      xl: 6,
      xxl: 6,
    },
  },
  {
    channelName: 'entrepreneur',
    logoSrc: '../../images/news-channels/entrepreneur.png',
    grid: {
      lg: 6,
      xl: 6,
      xxl: 6,
    },
  },
  {
    channelName: 'business-world',
    logoSrc: '../../images/news-channels/business-world.png',
    grid: {
      lg: 6,
      xl: 6,
      xxl: 6,
    },
  },
  {
    channelName: 'cnn-philippines',
    logoSrc: '../../images/news-channels/cnn-news.png',
    grid: {
      lg: 6,
      xl: 4,
      xxl: 4,
    },
  },
  {
    channelName: 'daily-inquirer',
    logoSrc: '../../images/news-channels/daily-inquirer.png',
    grid: {
      lg: 4,
      xl: 4,
      xxl: 4,
    },
  },
  {
    channelName: 'abscbn-news',
    logoSrc: '../../images/news-channels/abs-cbn-news.png',
    grid: {
      lg: 4,
      xl: 4,
      xxl: 4,
    },
  },
  {
    channelName: 'phil-star',
    logoSrc: '../../images/news-channels/phil-star.png',
    grid: {
      lg: 4,
      xl: 4,
      xxl: 4,
    },
  },
  {
    channelName: 'world-news-newspaper',
    logoSrc: '../../images/news-channels/world-news-newspaper.png',
    grid: {
      lg: 4,
      xl: 4,
      xxl: 4,
    },
  },
  {
    channelName: 'phil-graphic',
    logoSrc: '../../images/news-channels/phil-graphic.png',
    grid: {
      lg: 4,
      xl: 4,
      xxl: 4,
    },
  },
  {
    channelName: 'the-manila-times',
    logoSrc: '../../images/news-channels/manila-times.png',
    grid: {
      lg: 7,
      xl: 7,
      xxl: 7,
    },
  },
  {
    channelName: 'business-mirror',
    logoSrc: '../../images/news-channels/business-mirror.png',
    grid: {
      lg: 7,
      xl: 7,
      xxl: 7,
    },
  },
  {
    channelName: 'manila-bulletin',
    logoSrc: '../../images/news-channels/manila-bulletin.png',
    grid: {
      lg: 7,
      xl: 7,
      xxl: 7,
    },
  },
];

const StyledAsFeaturedOnComponent = styled.div`
  margin: 36px 0px;
  padding: 24px 0px;

  img {
    margin-bottom: 6px;
  }

  h2 {
    text-align: center;
    color: ${props => props.theme.primeBluePalette.original};
    border-bottom: 1px solid ${props => props.theme.primeBluePalette.original};
    margin-bottom: 36px;
  }
`;

function AsFeaturedOnComponent() {
  return (
    <StyledAsFeaturedOnComponent>
      <Row type="flex" justify="center" align="middle">
        <Col xxl={18} xl={18} lg={22} md={22} sm={22} xs={22}>
          <Row type="flex" justify="center">
            <Col
              xs={22}
              sm={22}
              md={20}
              lg={14}
              xl={14}
              xxl={14}
              style={{ textAlign: 'center' }}
            >
              <h2 className="section-title add-diamond-line">AS FEATURED ON</h2>
            </Col>
          </Row>
          <br />

          <Row type="flex" justify="space-around" gutter={16}>
            {mediaLogos.map(value => (
              <Col
                md={6}
                sm={11}
                xs={11}
                lg={value.grid.lg}
                xl={value.grid.xl}
                xxl={value.grid.xxl}
                key={value.channelName}
              >
                <img src={value.logoSrc} alt={value.channelName} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </StyledAsFeaturedOnComponent>
  );
}

export default AsFeaturedOnComponent;
