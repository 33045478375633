import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';

import HeroSectionComponent from '../components/press-room/HeroSectionComponent';
import MediaAppearancesComponent from '../components/press-room/MediaAppearancesComponent';
import DontMissItComponent from '../components/press-room/DontMissItComponent';
import OtherNewsArticlesComponent from '../components/press-room/OtherNewsArticlesComponent';
import AsFeaturedOnComponent from '../components/press-room/AsFeaturedOnComponent';
import MediaContactsComponent from '../components/press-room/MediaContactsComponent';

const DivWrapper = styled.div`
  overflow: hidden;
`;

function PressRoomPage({ location }) {
  return (
    <Layout source="Press Room">
      <SEO
        title="Press Releases and News Articles"
        description="The Leading Commercial Real Estate Consultancy Firm in the Philippines."
        meta={[
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content:
              'Real Estate Service Provider in PH,Real Estate Service in the Philippines,Real Estate Service Firm in PH,Real Estate Service Firm in the Philippines',
          },
        ]}
      />
      <DivWrapper>
        <HeroSectionComponent />
        <MediaAppearancesComponent />
        <DontMissItComponent />
        <OtherNewsArticlesComponent />
        <AsFeaturedOnComponent />
        <MediaContactsComponent />
      </DivWrapper>
    </Layout>
  );
}

export default PressRoomPage;
