import React from 'react';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Row, Col, Tabs, List, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

const { TabPane } = Tabs;
const { Item } = List;
const { Meta } = Item;

const StyledOtherNewsArticlesComponent = styled.div`
  margin: 36px 0px;
  padding: 24px 0px;
  h3 {
    color: ${props => props.theme.primeBluePalette.original};
  }

  .ant-list-item-meta-description {
    font-weight: 1.25;
    color: rgba(0, 0, 0, 0.75);
  }

  .ant-tabs {
    .ant-tabs-nav-wrap {
      .ant-tabs-tab {
        background-color: transparent;
        border: none;
        font-weight: bold;
      }

      .ant-tabs-tab-active {
        background-color: ${props => props.theme.primeBluePalette.original};
      }
      .ant-tabs-tab-active .ant-tabs-tab-btn {
        font-weight: bold;
        color: ${props => props.theme.primeYellowPalette.original} !important;
      }
    }
  }

  .ant-list-item-meta-title {
    margin-bottom: 8px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .tag {
      display: inline-block;
      padding: 6px;
      margin-bottom: 8px;
      font-size: 12px;
      line-height: 12px;
      background-color: ${props => props.theme.primeBluePalette.original};
      color: ${props => props.theme.whitePalette.original};
      font-weight: 100;

      .fa-newspaper {
        margin-right: 4px;
      }
    }

    .title {
      line-height: 16px;
    }

    .date {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: ${props => props.theme.bluePalette.original};
    }
  }

  .styled-button {
    font-size: 14px;
    border-color: ${props => props.theme.primeBluePalette.original};
    background-color: ${props => props.theme.primeBluePalette.original};
    margin: 8px 0px;
    color: ${props => props.theme.primeYellowPalette.original};

    :hover {
      opacity: 0.8;
    }

    .styled-icon {
      font-size: 12px;
      margin-left: 4px;
    }
  }

  .add-diamond-line:after {
    border-color: ${props => props.theme.primeBluePalette.original};
  }

  .header-wrapper.add-diamond-line {
    border-bottom: 1px solid #003366;
    margin-bottom: 36px;
    margin-top: -16px;
  }
  .news-wrapper.add-diamond-line {
    border-bottom: 1px solid #003366;
    margin-bottom: 16px;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border: initial;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 1px;
  }

  .ant-spin-container {
    > .ant-row {
      > div:nth-child(odd) {
        border-right: 1px solid #003366;
      }
    }
  }
`;

function OtherNewsArticlesComponent() {
  const data = useStaticQuery(graphql`
    {
      allStrapiNewsArchive(sort: { fields: published_date, order: DESC }) {
        nodes {
          visibility
          tags
          source_name
          strapiId
          picture
          published_date
          news_title
          id
          description
          content
          author
          url_slug
        }
      }
    }
  `);

  data.allStrapiNewsArchive.nodes.splice(0, 6);

  const articlesYear = data.allStrapiNewsArchive.nodes.reduce(
    (accumulator, currentValue) => {
      const yr = moment(currentValue.published_date).format('YYYY');
      if (!accumulator) return [yr];
      else {
        if (!accumulator.includes(yr)) return [...accumulator, yr];
      }
      return accumulator;
    },
    []
  );

  return (
    <StyledOtherNewsArticlesComponent>
      <Row type="flex" justify="center">
        <Col xxl={18} lg={20} md={22} sm={23} xs={23}>
          <h3>Other News Articles</h3>
          <Tabs defaultActiveKey={articlesYear[0]} type="card">
            {articlesYear.map(value => (
              <TabPane tab={value} key={value}>
                <div className="header-wrapper add-diamond-line"></div>

                <List
                  itemLayout="horizontal"
                  pagination={{ pageSize: 4 }}
                  grid={{ column: 2, gutter: 24, xs: 1, sm: 1 }}
                  dataSource={data.allStrapiNewsArchive.nodes.filter(
                    dataSrc =>
                      moment(dataSrc.published_date).format('YYYY') === value
                  )}
                  renderItem={item => (
                    <Item>
                      <Meta
                        title={
                          <div className="header">
                            <div>
                              <div className="tag">
                                <FontAwesomeIcon icon="newspaper" fixedWidth />
                                {item.source_name}
                              </div>
                              <div className="title">
                                <Link to={`/press-room/${item.url_slug}`}>
                                  {item.news_title}
                                </Link>
                              </div>
                            </div>
                          </div>
                        }
                        description={
                          <div className="news-wrapper add-diamond-line">
                            <div>
                              {moment(item.published_date).format(
                                'MMMM D, YYYY'
                              )}{' '}
                              • {item.description}
                            </div>
                            <Link to={`/press-room/${item.url_slug}`}>
                              <Button type="primary" className="styled-button">
                                Read More
                                <FontAwesomeIcon
                                  icon="chevron-right"
                                  className="styled-icon"
                                  fixedWidth
                                />
                              </Button>
                            </Link>
                          </div>
                        }
                      />
                    </Item>
                  )}
                />
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </StyledOtherNewsArticlesComponent>
  );
}

export default OtherNewsArticlesComponent;
