import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'antd';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledDivWrapper = styled.div`
  padding: 50px 0px;

  @media only screen and (max-width: 575px) {
    padding: 40px 0px 0px 0px;
  }

  h2 {
    color: ${props => props.theme.primeBluePalette.original};
    border-bottom: 1px solid ${props => props.theme.primeBluePalette.original};
    margin-bottom: 36px;
  }

  h4 {
    color: ${props => props.theme.primeBluePalette.original};
    margin-bottom: 6px;
  }

  .media-info {
    opacity: 0.85;
    font-size: 0.9em;
  }

  .carousel-indicators {
    span {
      cursor: pointer;
      width: 20px;
      height: 20px;
      background-color: transparent;
      border: 1px solid ${props => props.theme.primeBluePalette.original};
      margin-right: 5px;
      display: inline-block;
      :hover {
        background-color: ${props => props.theme.primeBluePalette.original};
      }
    }
    span.active-slide {
      background-color: ${props => props.theme.primeBluePalette.original};
    }
  }
  .iframe-wrapper {
    > div {
      background-color: black;
      height: 220px;
      margin-bottom: -8px;
      > iframe {
        margin-bottom: 0;
      }
    }
  }

  .media-content {
    padding: 0px 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media only screen and (max-width: 991px) {
      padding: 0;
    }
  }

  .media-wrapper {
    padding: 0px 6px;
  }

  .slick-dots > li {
    width: 50px;
    height: 20px;
  }

  .slider-pager {
    border: 1px solid ${props => props.theme.primeBluePalette.original};
    height: 10px;
  }

  .slick-dots > li.slick-active {
    width: 75px;
    .slider-pager {
      background-color: ${props => props.theme.primeBluePalette.original};
    }
  }

  .slick-dots {
    bottom: -50px;
  }

  .next-slider {
    display: block;
    position: absolute;
    top: calc(50% - 35px);
    right: -50px;
    font-size: 50px;
    cursor: pointer;
  }

  .prev-slider {
    display: block;
    position: absolute;
    top: calc(50% - 35px);
    left: -50px;
    font-size: 50px;
    cursor: pointer;
  }
`;

function NextArrow(props) {
  // eslint-disable-next-line react/prop-types
  const { style, onClick } = props;
  return (
    <div
      className="next-slider"
      style={{ ...style, display: 'block', color: '#003366' }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon="chevron-right" fixedWidth />
    </div>
  );
}

function PrevArrow(props) {
  // eslint-disable-next-line react/prop-types
  const { style, onClick } = props;
  return (
    <div
      className="prev-slider"
      style={{ ...style, display: 'block', color: '#003366' }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon="chevron-left" fixedWidth />
    </div>
  );
}

function MediaAppearancesComponent() {
  const data = useStaticQuery(graphql`
    {
      allStrapiMediaAppearance(sort: { order: DESC, fields: published_date }) {
        nodes {
          strapiId
          description
          embedded_iframe
          featured
          published_date(formatString: "MMMM DD, YYYY")
          thumb
          title
          channel
        }
      }
    }
  `);

  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const slickRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    swipe: false,
    afterChange: index => {
      setCurrentMediaIndex(index);
    },
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          swipe: true,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
        },
      },
    ],
    customPaging: i => (
      <div
        className={`slider-pager ${i === currentMediaIndex ? 'active' : ''}`}
      />
    ),
  };

  return (
    <StyledDivWrapper>
      <div>
        <Row type="flex" justify="center">
          <Col xs={19} sm={19} md={22} lg={22} xl={18} xxl={16}>
            <Row type="flex" justify="center">
              <Col
                xs={22}
                sm={22}
                md={20}
                lg={14}
                xl={14}
                xxl={14}
                style={{ textAlign: 'center' }}
              >
                <h2 className="section-title add-diamond-line">
                  MEDIA APPEARANCES
                </h2>
              </Col>
            </Row>

            <br />

            <Slider {...settings} ref={slickRef}>
              {data.allStrapiMediaAppearance.nodes.map(media => (
                <div className="media-wrapper" key={media.strapiId}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: media.embedded_iframe,
                    }}
                  />
                  <h4>{media.title}</h4>
                  <p className="media-info">
                    <FontAwesomeIcon
                      icon="newspaper"
                      fixedWidth
                      color="#003366"
                    />{' '}
                    {media.channel}
                    {'   '}
                    <FontAwesomeIcon
                      icon="calendar-alt"
                      fixedWidth
                      color="#003366"
                    />{' '}
                    {media.published_date}
                  </p>
                  <p>{media.description}</p>
                </div>
              ))}
            </Slider>
          </Col>
        </Row>
      </div>
    </StyledDivWrapper>
  );
}

export default MediaAppearancesComponent;
