import React, { useRef } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStaticQuery, graphql } from 'gatsby';
import moment from 'moment';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledDontMissItComponent = styled.div`
  margin: 36px 0px;
  padding: 24px 0px;

  .header {
    display: flex;
    margin-bottom: 16px;

    h2 {
      margin-left: 15px;
      padding: 16px;
      background-color: ${props => props.theme.primeBluePalette.original};
      color: ${props => props.theme.primeYellowPalette.original};
      margin: 0;
      box-shadow: -5px 5px 0px 0px ${props => props.theme.primeBluePalette.dark};
    }
  }

  .next-slider {
    display: block;
    position: absolute;
    top: calc(50% - 35px);
    right: -50px;
    font-size: 50px;
    cursor: pointer;
  }

  .prev-slider {
    display: block;
    position: absolute;
    top: calc(50% - 35px);
    left: -50px;
    font-size: 50px;
    cursor: pointer;
  }
`;

const StyledArticle = styled.a`
  padding: 10px;
  > div {
    background-image: ${props => `url('${props.backgroundImage}')`};
    height: 250px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.4);

    .details {
      z-index: 1;
      margin: 0px 16px;

      .tag {
        display: inline-block;
        padding: 4px;
        margin-bottom: 8px;
        font-size: 12px;
        line-height: 12px;
        background-color: ${props => props.theme.whitePalette.original};
        color: ${props => props.theme.primeBluePalette.original};
        border-radius: 2px;

        .fa-newspaper {
          margin-right: 4px;
        }
      }

      .title {
        line-height: 30px;
        font-size: 26px;
        color: ${props => props.theme.primeYellowPalette.original};
        font-weight: 600;
        margin-bottom: 8px;
      }

      .subtitle {
        font-size: 14px;
        line-height: 16px;
        color: ${props => props.theme.whitePalette.original};
        margin-bottom: 16px;
      }
    }
    ::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: ${props => `${props.theme.primeBluePalette.original}E0`};
    }

    .flex-wrapper {
      display: flex;
      align-items: center;
    }
  }
`;

function NextArrow(props) {
  // eslint-disable-next-line react/prop-types
  const { style, onClick } = props;
  return (
    <div
      className="next-slider"
      style={{
        ...style,
        display: 'block',
        color: `#003366${onClick === null ? '99' : ''}`,
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon="chevron-right" fixedWidth />
    </div>
  );
}

function PrevArrow(props) {
  // eslint-disable-next-line react/prop-types
  const { style, onClick } = props;
  return (
    <div
      className="prev-slider"
      style={{
        ...style,
        display: 'block',
        color: `#003366${onClick === null ? '99' : ''}`,
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon="chevron-left" fixedWidth />
    </div>
  );
}

function DontMissItComponent() {
  const data = useStaticQuery(graphql`
    {
      allStrapiNewsArchive(sort: { fields: published_date, order: DESC }) {
        nodes {
          visibility
          tags
          source_name
          strapiId
          picture
          published_date
          news_title
          id
          description
          content
          author
          url_slug
        }
      }
    }
  `);
  const slickRef = useRef(null);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    swipe: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const newsData = data.allStrapiNewsArchive.nodes.slice(0, 6);

  return (
    <StyledDontMissItComponent>
      <Row type="flex" justify="center">
        <Col xxl={18} lg={22} md={20} sm={20} xs={20}>
          <div className="header">
            <h2>DON&apos;T MISS IT</h2>
          </div>
          <Slider {...settings} ref={slickRef}>
            {newsData.map(value => (
              <StyledArticle
                key={value.id}
                href={`/press-room/${value.url_slug}`}
                backgroundImage={
                  value.picture.startsWith('https://') ||
                  value.picture.startsWith('http://')
                    ? value.picture
                    : `${process.env.IMAGE_ASSETS}/news-archive/${value.picture}`
                }
              >
                <div>
                  <div className="details">
                    <div className="title">{value.news_title}</div>
                    <div className="subtitle">
                      <div className="flex-wrapper">
                        <FontAwesomeIcon
                          icon="newspaper"
                          fixedWidth
                          style={{ marginRight: 10 }}
                        />
                        <div>
                          {value.source_name} • {value.author} •{' '}
                          {`${value.source_name}  • ${moment(
                            value.published_date
                          ).format('MMMM DD, YYYY')}`}
                          {/* {`${value.source_name} • ${value.author} • ${moment(
                            value.published_date
                          ).format('MMMM DD, YYYY')}`} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </StyledArticle>
            ))}
          </Slider>
        </Col>
      </Row>
    </StyledDontMissItComponent>
  );
}

export default DontMissItComponent;
